export const adminMenuItems = [
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 3,
        label: 'menuitems.activities.text',
        icon: 'ri-window-line',
        link: '/activities'
    },
    {
        id: 4,
        label: 'menuitems.report.text',
        icon: 'ri-file-chart-line',
        link: '/report'
    },
    {
        id: 5,
        label: 'menuitems.apps.text',
        icon: 'ri-apps-line',
        link: '/apps'
    },
    // {
    //     id: 12,
    //     label: 'menuitems.urls.text',
    //     icon: 'ri-link',
    //     link: '/urls'
    // },

    {
        id: 6,
        label: 'menuitems.manage-tasks.text',
        icon: 'ri-file-edit-line',
        link: '/tasks'
    },
    {
        id: 7,
        label: 'menuitems.manage-projects.text',
        icon: 'ri-folder-line',
        link: '/projects'
    },

    {
        id: 8,
        label: 'menuitems.manage-organizations.text',
        icon: 'ri-building-line',
        link: '/departments'
    },

    {
        id: 9,
        label: 'menuitems.workspace.text',
        icon: 'ri-building-2-line',
        link: '/company'
    },

    {
        id: 10,
        label: 'menuitems.Members.text',
        icon: 'ri-user-line',
        link: '/members'
    }
    ,
    {
        id: 11,
        label: 'menuitems.integration.text',
        icon: 'ri-links-line',
        link: '/integration'
    },
    {
        id: 13,
        label: 'menuitems.request.text',
        icon: 'ri-message-2-line',
        link: '/requests'
    },
    {
        id: 12,
        label: 'menuitems.download.text',
        icon: 'ri-file-download-line',
        link: '/download'
    },

]

export const userMenuItems = [
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 3,
        label: 'menuitems.activities.text',
        icon: 'ri-window-line',
        link: '/activities'
    },
    {
        id: 8,
        label: 'menuitems.report.text',
        icon: 'ri-file-chart-line',
        link: '/report'
    },
    {
        id: 4,
        label: 'menuitems.apps.text',
        icon: 'ri-apps-line',
        link: '/apps'
    },
    // {
    //         id: 12,
    //     label: 'menuitems.urls.text',
    //     icon: 'ri-link',
    //     link: '/urls'
    // },


    {
        id: 5,
        label: 'menuitems.manage-tasks.text',
        icon: 'ri-file-edit-line',
        link: '/tasks'
    },
    {
        id: 6,
        label: 'menuitems.manage-projects.text',
        icon: 'ri-folder-line',
        link: '/projects'
    },
    // {
    //     id: 7,
    //     label: 'menuitems.manage-organizations.text',
    //     icon: 'ri-building-line',
    //     link: '/departments'
    // },
    {
        id: 9,
        label: 'menuitems.integration.text',
        icon: 'ri-links-line',
        link: '/integration'
    },
    {
        id: 10,
        label: 'menuitems.download.text',
        icon: 'ri-file-download-line',
        link: '/download'
    },
]
