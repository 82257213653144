<script>
import i18n from "../i18n";
import Preference from "@/data/Preference"

let preference = new Preference()
import {bus} from '../views/pages/dashboard/index'

let userImage = require("@/assets/images/users/avatar.png")

export default {
  props: ['auto_refresh_val'],
  data() {
    return {
      role: preference.getData(Preference.KEY_ROLE_ID),
            userName: "",
      userImage: "",
      // languages: [
      //   {
      //     flag: require("@/assets/images/flags/us.jpg"),
      //     language: "en",
      //     title: "English"
      //   },
      //   {
      //     flag: require("@/assets/images/flags/french.jpg"),
      //     language: "fr",
      //     title: "French"
      //   },
      //   {
      //     flag: require("@/assets/images/flags/spain.jpg"),
      //     language: "es",
      //     title: "spanish"
      //   },
      //   {
      //     flag: require("@/assets/images/flags/chaina.png"),
      //     language: "zh",
      //     title: "Chinese"
      //   },
      //   {
      //     flag: require("@/assets/images/flags/arabic.png"),
      //     language: "ar",
      //     title: "Arabic"
      //   }
      // ],
      current_language: "en",
      selected_auto_ref: null,
      ref_options: [
        {value: 0, text: 'Off', view: '(Off)'},
        {value: 5, text: '5 min', view: '(5 min)'},
        {value: 10, text: '10 min', view: '(10 min)'},
        {value: 20, text: '20 min', view: '(20 min)'},
      ]
    };
  },
  components: {
    //simplebar
  },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
          !document.fullscreenElement &&
          /* alternative standard method */ !document.mozFullScreenElement &&
          !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
              Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    setLanguage(locale) {
      i18n.locale = locale;
      this.current_language = i18n.locale;
    },
    setAutoRefreshOptions(item) {
      this.selected_auto_ref = item.view;
      preference.setData(Preference.KEY_AUTO_REFRESH_TIME, item.value)
      preference.setData(Preference.KEY_AUTO_REFRESH_KEY, item.text)
      preference.setData(Preference.KEY_AUTO_REFRESH_VIEW, item.view)
      bus.$emit('auto_refresh_val', item.value)
    },
  },
  created() {

    bus.$on('user_name', data => {
      this.userName = data
    })

    bus.$on('user_image', data => {
      this.userImage = data
    })
  },
  mounted() {
    this.userName = preference.getProfileData().name
    let imageData = preference.getProfileData().image
    if (imageData && imageData != null && imageData != 'null') {
      this.userImage = imageData
    } else {
      this.userImage = userImage
    }
    if (preference.getData(Preference.KEY_AUTO_REFRESH_VIEW)) {
      this.selected_auto_ref = preference.getData(Preference.KEY_AUTO_REFRESH_VIEW)
    } else {
      this.selected_auto_ref = '(10 min)'
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box border border-right">
          <!--          <a href="/" class="logo logo-dark">
                      <span class="logo-sm">
                        <img src="@/assets/images/workany-logo.png" alt height="22"/>
                      </span>
                      <span class="logo-lg">
                        <img src="@/assets/images/workany-logo.png" alt height="20"/>
                      </span>
                    </a>-->

          <a href="/" class="logo">
            <span class="logo-sm">
              <img src="@/assets/images/workany-light-sm.png" alt height="40" style="margin-left: -9px"/>
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/workany-light-lg.svg" alt height="40" width="180"/>
            </span>
          </a>
        </div>

        <button
            @click="toggleMenu"
            type="button"
            class="btn btn-sm px-2 px-sm-3 font-size-24 header-item waves-effect"
            id="vertical-menu-btn"
        >
          <i class="ri-menu-2-line align-middle" style="color: #ff7c47;"></i>
        </button>

        <!-- App Search-->
        <!--        <form class="app-search d-none d-lg-block">-->
        <!--          <div class="position-relative">-->
        <!--            <input type="text" class="form-control" :placeholder="$t('navbar.search.text')" />-->
        <!--            <span class="ri-search-line"></span>-->
        <!--          </div>-->
        <!--        </form>-->

        <!--        <b-dropdown-->
        <!--          variant="black"-->
        <!--          class="dropdown-mega d-none d-lg-block ml-2"-->
        <!--          toggle-class="header-item"-->
        <!--          menu-class="dropdown-megamenu"-->
        <!--        >-->
        <!--          <template v-slot:button-content>-->
        <!--            {{ $t('navbar.dropdown.megamenu.text') }}-->
        <!--            <i class="mdi mdi-chevron-down"></i>-->
        <!--          </template>-->
        <!--          <div class="row">-->
        <!--            <div class="col-sm-8">-->
        <!--              <div class="row">-->
        <!--                <div class="col-md-4">-->
        <!--                  <h5 class="font-size-14 mt-0">{{ $t('navbar.dropdown.megamenu.uicontent.title') }}</h5>-->
        <!--                  <ul class="list-unstyled megamenu-list">-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.uicontent.list.lightbox') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.uicontent.list.rangeslider') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.uicontent.list.sweetalert') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.uicontent.list.rating') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.uicontent.list.forms') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.uicontent.list.tables') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.uicontent.list.charts') }}</a>-->
        <!--                    </li>-->
        <!--                  </ul>-->
        <!--                </div>-->

        <!--                <div class="col-md-4">-->
        <!--                  <h5-->
        <!--                    class="font-size-14 mt-0"-->
        <!--                  >{{ $t('navbar.dropdown.megamenu.application.title') }}</h5>-->
        <!--                  <ul class="list-unstyled megamenu-list">-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.application.list.ecommerce') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.application.list.calendar') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.application.list.email') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.application.list.projects') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.application.list.tasks') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.application.list.contacts') }}</a>-->
        <!--                    </li>-->
        <!--                  </ul>-->
        <!--                </div>-->

        <!--                <div class="col-md-4">-->
        <!--                  <h5-->
        <!--                    class="font-size-14 mt-0"-->
        <!--                  >{{ $t('navbar.dropdown.megamenu.extrapages.title') }}</h5>-->
        <!--                  <ul class="list-unstyled megamenu-list">-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.extrapages.list.lightsidebar') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.extrapages.list.compactsidebar') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.extrapages.list.horizontallayout') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.extrapages.list.maintenance') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.extrapages.list.comingsoon') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.extrapages.list.timeline') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.extrapages.list.faqs') }}</a>-->
        <!--                    </li>-->
        <!--                  </ul>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div class="col-sm-4">-->
        <!--              <div class="row">-->
        <!--                <div class="col-sm-6">-->
        <!--                  <h5 class="font-size-14 mt-0">{{ $t('navbar.dropdown.megamenu.uicontent.title') }}</h5>-->
        <!--                  <ul class="list-unstyled megamenu-list">-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.uicontent.list.lightbox') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.uicontent.list.rangeslider') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.uicontent.list.sweetalert') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.uicontent.list.rating') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.uicontent.list.forms') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.uicontent.list.tables') }}</a>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                      <a-->
        <!--                        href="javascript:void(0);"-->
        <!--                      >{{ $t('navbar.dropdown.megamenu.uicontent.list.charts') }}</a>-->
        <!--                    </li>-->
        <!--                  </ul>-->
        <!--                </div>-->

        <!--                <div class="col-sm-5">-->
        <!--                  <div>-->
        <!--                    <img-->
        <!--                      src="@/assets/images/megamenu-img.png"-->
        <!--                      alt-->
        <!--                      class="img-fluid mx-auto d-block"-->
        <!--                    />-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </b-dropdown>-->
      </div>

      <div class="d-flex">
        <!--        <div class="dropdown d-inline-block d-lg-none ml-2">-->
        <!--          <button-->
        <!--            type="button"-->
        <!--            class="btn header-item noti-icon waves-effect"-->
        <!--            id="page-header-search-dropdown"-->
        <!--            data-toggle="dropdown"-->
        <!--            aria-haspopup="true"-->
        <!--            aria-expanded="false"-->
        <!--          >-->
        <!--            <i class="ri-search-line"></i>-->
        <!--          </button>-->
        <!--          <div-->
        <!--            class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"-->
        <!--            aria-labelledby="page-header-search-dropdown"-->
        <!--          >-->
        <!--            <form class="p-3">-->
        <!--              <div class="form-group m-0">-->
        <!--                <div class="input-group">-->
        <!--                  <input type="text" class="form-control" placeholder="Search ..." />-->
        <!--                  <div class="input-group-append">-->
        <!--                    <button class="btn btn-primary" type="submit">-->
        <!--                      <i class="ri-search-line"></i>-->
        <!--                    </button>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </form>-->
        <!--          </div>-->
        <!--        </div>-->

        <!-- auto refresh btn-->
        <b-dropdown variant="white" right toggle-class="header-item">
          <template v-slot:button-content >

            <div class="d-flex align-items-center " v-b-tooltip.hover.left title="Auto Refresh">
              <i style="color:#ff7c47" class="ri-refresh-line font-size-24 px-2"></i>
              {{ selected_auto_ref }}
              <i class="mdi mdi-chevron-down"></i>
            </div>

          </template>
          <b-dropdown-item
              class="notify-item"
              v-for="(entry, i) in ref_options"
              :key="`Lang${i}`"
              :value="entry"
              @click="setAutoRefreshOptions(entry)"
              :link-class="{'active': entry.view === selected_auto_ref}"
          >
            <span class="align-middle">{{ entry.text }}</span>
          </b-dropdown-item>
        </b-dropdown>

        <!--        <b-dropdown-->
        <!--          class="d-none d-lg-inline-block d-lg-inline-block ml-1"-->
        <!--          menu-class="dropdown-menu-lg"-->
        <!--          right-->
        <!--          toggle-class="header-item noti-icon"-->
        <!--          variant="black"-->
        <!--        >-->
        <!--          <template v-slot:button-content>-->
        <!--            <i class="ri-apps-2-line"></i>-->
        <!--          </template>-->
        <!--          <div class="px-lg-2">-->
        <!--            <div class="row no-gutters">-->
        <!--              <div class="col">-->
        <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
        <!--                  <img src="@/assets/images/brands/github.png" alt="Github" />-->
        <!--                  <span>{{ $t('navbar.dropdown.site.list.github') }}</span>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--              <div class="col">-->
        <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
        <!--                  <img src="@/assets/images/brands/bitbucket.png" alt="bitbucket" />-->
        <!--                  <span>{{ $t('navbar.dropdown.site.list.github') }}</span>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--              <div class="col">-->
        <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
        <!--                  <img src="@/assets/images/brands/dribbble.png" alt="dribbble" />-->
        <!--                  <span>{{ $t('navbar.dropdown.site.list.dribbble') }}</span>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--            </div>-->

        <!--            <div class="row no-gutters">-->
        <!--              <div class="col">-->
        <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
        <!--                  <img src="@/assets/images/brands/dropbox.png" alt="dropbox" />-->
        <!--                  <span>{{ $t('navbar.dropdown.site.list.dropbox') }}</span>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--              <div class="col">-->
        <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
        <!--                  <img src="@/assets/images/brands/mail_chimp.png" alt="mail_chimp" />-->
        <!--                  <span>{{ $t('navbar.dropdown.site.list.mailchimp') }}</span>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--              <div class="col">-->
        <!--                <a class="dropdown-icon-item" href="javascript: void(0);">-->
        <!--                  <img src="@/assets/images/brands/slack.png" alt="slack" />-->
        <!--                  <span>{{ $t('navbar.dropdown.site.list.slack') }}</span>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </b-dropdown>-->

        <!-- full screen btn-->
        <div class="dropdown d-none d-lg-inline-block">
          <button
              type="button"
              class="btn header-item noti-icon waves-effect"
              @click="initFullScreen"
          >
            <i class="ri-fullscreen-line" style="color:#ff7c47"></i>
          </button>
        </div>

        <!--<b-dropdown
            right
            menu-class="dropdown-menu-lg p-0"
            toggle-class="header-item noti-icon"
            variant="black"
        >
          &lt;!&ndash;          <template v-slot:button-content>&ndash;&gt;
          &lt;!&ndash;            <i class="ri-notification-3-line"></i>&ndash;&gt;
          &lt;!&ndash;            <span class="noti-dot"></span>&ndash;&gt;
          &lt;!&ndash;          </template>&ndash;&gt;
          &lt;!&ndash;          <div class="p-3">&ndash;&gt;
          &lt;!&ndash;            <div class="row align-items-center">&ndash;&gt;
          &lt;!&ndash;              <div class="col">&ndash;&gt;
          &lt;!&ndash;                <h6 class="m-0">{{ $t('navbar.dropdown.notification.text')}}</h6>&ndash;&gt;
          &lt;!&ndash;              </div>&ndash;&gt;
          &lt;!&ndash;              <div class="col-auto">&ndash;&gt;
          &lt;!&ndash;                <a href="#!" class="small">{{ $t('navbar.dropdown.notification.subtext')}}</a>&ndash;&gt;
          &lt;!&ndash;              </div>&ndash;&gt;
          &lt;!&ndash;            </div>&ndash;&gt;
          &lt;!&ndash;          </div>&ndash;&gt;
          &lt;!&ndash;          <simplebar style="max-height: 230px;">&ndash;&gt;
          &lt;!&ndash;            <a href class="text-reset notification-item">&ndash;&gt;
          &lt;!&ndash;              <div class="media">&ndash;&gt;
          &lt;!&ndash;                <div class="avatar-xs mr-3">&ndash;&gt;
          &lt;!&ndash;                  <span class="avatar-title bg-primary rounded-circle font-size-16">&ndash;&gt;
          &lt;!&ndash;                    <i class="ri-shopping-cart-line"></i>&ndash;&gt;
          &lt;!&ndash;                  </span>&ndash;&gt;
          &lt;!&ndash;                </div>&ndash;&gt;
          &lt;!&ndash;                <div class="media-body">&ndash;&gt;
          &lt;!&ndash;                  <h6 class="mt-0 mb-1">{{ $t('navbar.dropdown.notification.order.title')}}</h6>&ndash;&gt;
          &lt;!&ndash;                  <div class="font-size-12 text-muted">&ndash;&gt;
          &lt;!&ndash;                    <p class="mb-1">{{ $t('navbar.dropdown.notification.order.text')}}</p>&ndash;&gt;
          &lt;!&ndash;                    <p class="mb-0">&ndash;&gt;
          &lt;!&ndash;                      <i class="mdi mdi-clock-outline"></i>&ndash;&gt;
          &lt;!&ndash;                      {{ $t('navbar.dropdown.notification.order.time')}}&ndash;&gt;
          &lt;!&ndash;                    </p>&ndash;&gt;
          &lt;!&ndash;                  </div>&ndash;&gt;
          &lt;!&ndash;                </div>&ndash;&gt;
          &lt;!&ndash;              </div>&ndash;&gt;
          &lt;!&ndash;            </a>&ndash;&gt;
          &lt;!&ndash;            <a href class="text-reset notification-item">&ndash;&gt;
          &lt;!&ndash;              <div class="media">&ndash;&gt;
          &lt;!&ndash;                <img&ndash;&gt;
          &lt;!&ndash;                  src="@/assets/images/users/avatar-3.jpg"&ndash;&gt;
          &lt;!&ndash;                  class="mr-3 rounded-circle avatar-xs"&ndash;&gt;
          &lt;!&ndash;                  alt="user-pic"&ndash;&gt;
          &lt;!&ndash;                />&ndash;&gt;
          &lt;!&ndash;                <div class="media-body">&ndash;&gt;
          &lt;!&ndash;                  <h6 class="mt-0 mb-1">{{ $t('navbar.dropdown.notification.james.title')}}</h6>&ndash;&gt;
          &lt;!&ndash;                  <div class="font-size-12 text-muted">&ndash;&gt;
          &lt;!&ndash;                    <p class="mb-1">{{ $t('navbar.dropdown.notification.james.text')}}</p>&ndash;&gt;
          &lt;!&ndash;                    <p class="mb-0">&ndash;&gt;
          &lt;!&ndash;                      <i class="mdi mdi-clock-outline"></i>&ndash;&gt;
          &lt;!&ndash;                      {{ $t('navbar.dropdown.notification.james.time')}}&ndash;&gt;
          &lt;!&ndash;                    </p>&ndash;&gt;
          &lt;!&ndash;                  </div>&ndash;&gt;
          &lt;!&ndash;                </div>&ndash;&gt;
          &lt;!&ndash;              </div>&ndash;&gt;
          &lt;!&ndash;            </a>&ndash;&gt;
          &lt;!&ndash;            <a href class="text-reset notification-item">&ndash;&gt;
          &lt;!&ndash;              <div class="media">&ndash;&gt;
          &lt;!&ndash;                <div class="avatar-xs mr-3">&ndash;&gt;
          &lt;!&ndash;                  <span class="avatar-title bg-success rounded-circle font-size-16">&ndash;&gt;
          &lt;!&ndash;                    <i class="ri-checkbox-circle-line"></i>&ndash;&gt;
          &lt;!&ndash;                  </span>&ndash;&gt;
          &lt;!&ndash;                </div>&ndash;&gt;
          &lt;!&ndash;                <div class="media-body">&ndash;&gt;
          &lt;!&ndash;                  <h6 class="mt-0 mb-1">{{ $t('navbar.dropdown.notification.item.title')}}</h6>&ndash;&gt;
          &lt;!&ndash;                  <div class="font-size-12 text-muted">&ndash;&gt;
          &lt;!&ndash;                    <p class="mb-1">{{ $t('navbar.dropdown.notification.item.text')}}</p>&ndash;&gt;
          &lt;!&ndash;                    <p class="mb-0">&ndash;&gt;
          &lt;!&ndash;                      <i class="mdi mdi-clock-outline"></i>&ndash;&gt;
          &lt;!&ndash;                      {{ $t('navbar.dropdown.notification.item.time')}}&ndash;&gt;
          &lt;!&ndash;                    </p>&ndash;&gt;
          &lt;!&ndash;                  </div>&ndash;&gt;
          &lt;!&ndash;                </div>&ndash;&gt;
          &lt;!&ndash;              </div>&ndash;&gt;
          &lt;!&ndash;            </a>&ndash;&gt;
          &lt;!&ndash;            <a href class="text-reset notification-item">&ndash;&gt;
          &lt;!&ndash;              <div class="media">&ndash;&gt;
          &lt;!&ndash;                <img&ndash;&gt;
          &lt;!&ndash;                  src="@/assets/images/users/avatar-4.jpg"&ndash;&gt;
          &lt;!&ndash;                  class="mr-3 rounded-circle avatar-xs"&ndash;&gt;
          &lt;!&ndash;                  alt="user-pic"&ndash;&gt;
          &lt;!&ndash;                />&ndash;&gt;
          &lt;!&ndash;                <div class="media-body">&ndash;&gt;
          &lt;!&ndash;                  <h6 class="mt-0 mb-1">{{ $t('navbar.dropdown.notification.salena.title')}}</h6>&ndash;&gt;
          &lt;!&ndash;                  <div class="font-size-12 text-muted">&ndash;&gt;
          &lt;!&ndash;                    <p class="mb-1">{{ $t('navbar.dropdown.notification.salena.text')}}</p>&ndash;&gt;
          &lt;!&ndash;                    <p class="mb-0">&ndash;&gt;
          &lt;!&ndash;                      <i class="mdi mdi-clock-outline"></i>&ndash;&gt;
          &lt;!&ndash;                      {{ $t('navbar.dropdown.notification.salena.time')}}&ndash;&gt;
          &lt;!&ndash;                    </p>&ndash;&gt;
          &lt;!&ndash;                  </div>&ndash;&gt;
          &lt;!&ndash;                </div>&ndash;&gt;
          &lt;!&ndash;              </div>&ndash;&gt;
          &lt;!&ndash;            </a>&ndash;&gt;
          &lt;!&ndash;          </simplebar>&ndash;&gt;
          &lt;!&ndash;          <div class="p-2 border-top">&ndash;&gt;
          &lt;!&ndash;            <a&ndash;&gt;
          &lt;!&ndash;              class="btn btn-sm btn-link font-size-14 btn-block text-center"&ndash;&gt;
          &lt;!&ndash;              href="javascript:void(0)"&ndash;&gt;
          &lt;!&ndash;            >&ndash;&gt;
          &lt;!&ndash;              <i class="mdi mdi-arrow-right-circle mr-1"></i>&ndash;&gt;
          &lt;!&ndash;              {{ $t('navbar.dropdown.notification.button')}}&ndash;&gt;
          &lt;!&ndash;            </a>&ndash;&gt;
          &lt;!&ndash;          </div>&ndash;&gt;
        </b-dropdown>-->

        <!-- logout dropdown-->
        <b-dropdown
            right
            variant="black"
            toggle-class="header-item"
            class="d-inline-block user-dropdown"
        >
          <template v-slot:button-content>
            <img
                class="rounded-circle header-profile-user"
                :src="userImage"
                alt="Header Avatar"
            />
            <span class="d-inline-block text-truncate align-middle header-user-name ml-1">{{ userName }}</span>
            <i class="mdi mdi-chevron-down"></i>
          </template>
          <!-- <b-badge pill variant="primary" class="ml-4" > {{ this.role == 3 ? 'Super Admin' : this.role == 2 ? 'Admin' : 'Member' }}</b-badge> -->
          <a class="dropdown-item" href="/profile">
            <i class="ri-user-line align-middle mr-1"></i>
            Profile
          </a>
          <a class="dropdown-item text-danger" href="/logout">
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ $t('navbar.dropdown.kevin.list.logout') }}
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header-user-name {
  max-width: 200px;
}

.notify-item {
  .active {
    color: #16181b;
    background-color: #f8f9fa;
  }
}

.fullscreen-enable .ri-fullscreen-line:before {
  content: "\ed73";
}

@media only screen and (max-width: 575px) {
  .navbar-brand-box {
    display: block;
    padding: 0 1rem;
  }
  .header-user-name {
    max-width: 110px;
  }
}

@media only screen and (max-width: 350px) {
  .header-user-name {
    max-width: 75px;
  }
}

.navbar-brand-box {
  box-shadow: rgba(17, 17, 26, 0.1) 1px -8px 16px 7px, rgba(17, 17, 26, 0.05) 3px 12px 32px;
  //background-color: #ffffff !important;
}


</style>
